@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.App {
  text-align: center;
  padding: 20px;
  max-width: 500px;
  margin: auto;
  background-color: #f0f0f0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.balance {
  margin-bottom: 20px;
}

.transaction-input input {
  margin-bottom: 5px;
  padding: 10px;

}

.transaction-input button {
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
}

.transaction-input .entrada {
  background-color: #34C759;
  color: #fff;
  padding: 10px;
}

.transaction-input .saida {
  background-color: #FF3737;
  color: #fff;
  padding: 10px;
}

.transaction-list ul {
  list-style: none;
  padding: 0;
}

.transaction-list li {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

.transaction-list .entrada {
  background-color: #d4edda;
}

.transaction-list .saida {
  background-color: #edd4d4;
}

/* Adicionei a classe floating-ui para o botão de abrir modal */
.open-modal-btn {
  position: relative;
}

.saldo-positivo {
  color: #34C759;
}

.saldo-negativo {
  color: #FF3737;
}